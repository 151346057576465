import React, { useState, useRef } from "react"
import { graphql, useStaticQuery } from "gatsby"
import ValidatedForm, {
  useFormField,
  validateEmail,
} from "@coreyward/validated-form"
import { jsx } from "@emotion/core"
import { upperFirst } from "lodash-es"
import { motion } from "framer-motion"
import theme from "styles/theme"
import submitToNetlify from "lib/submitToNetlify"
import ContentBlock from "components/ContentBlock"
import InvisibleButton from "components/InvisibleButton"
import Button from "components/Button"
import { useAppState } from "components/Layout"
import useOnClickOutside from "hooks/useOnClickOutside"
import useTranslate from "hooks/useTranslate"

const ContactPanel = () => {
  const { data } = useStaticQuery(contactFormQuery)
  const { title, copy, successMessage } = data
  const { dispatch } = useAppState()
  const [submitted, setSubmitted] = useState()
  const ref = useRef()
  const buttonText = useTranslate("Send")

  useOnClickOutside(ref, () => {
    dispatch("closeContactPanel")
  })

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, rotateX: -90, originY: 0, perspective: 500 }}
      animate={{ opacity: 1, rotateX: 0 }}
      exit={{ opacity: 0, rotateX: -90 }}
      transition={{ type: "spring", damping: 24, stiffness: 200 }}
      css={{
        position: "absolute",
        zIndex: 7,
        left: 0,
        right: 0,
        top: "100%",
        maxHeight: "calc(100vh - 59px - 75px)", // viewport - header height - top & bottom margin
        overflowY: "auto",
        background: "inherit",
        boxShadow: theme.shadows.tight,
        borderRadius: 4,
        [theme.mq.mobile]: {
          maxHeight: "calc(100vh - 42px - 50px)",
        },
        [theme.mq.max(450)]: {
          width: "100%",
        },
      }}
    >
      <div
        css={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "stretch",
          padding: "48px 56px",
          [theme.mq.mobile]: {
            padding: "24px 32px",
          },
          [theme.mq.smallMobile]: {
            flexDirection: "column",
            padding: 24,
          },
        }}
      >
        <div
          css={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            flexDirection: "column",
            flex: "1 1 50%",
            minWidth: 275,
            paddingRight: "3em",
            [theme.mq.smallMobile]: {
              paddingRight: 0,
              marginBottom: 20,
            },
          }}
        >
          <div>
            <h2 css={{ ...theme.h2, marginBottom: 16 }}>{title}</h2>
            <ContentBlock
              content={copy}
              css={{
                ...theme.p2,
                maxWidth: 430,
                color: theme.colors.secondaryText,
              }}
            />
          </div>

          <div
            css={{
              flex: "0 0 52px",
              [theme.mq.smallMobile]: { display: "none" },
            }}
          >
            <InvisibleButton
              aria-label="Close Contact Form"
              onClick={() => {
                dispatch("closeContactPanel")
              }}
              css={{
                width: 52,
                height: 52,
                borderRadius: 52,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: theme.colors.primaryBackground,
                boxShadow: theme.shadows.tight,
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 27 28"
                width={27}
              >
                <path
                  fill={theme.colors.secondaryText}
                  d="M5.06 10.55l.6.62c.2.18.44.18.62 0l6.18-6.18v18.28c0 .25.18.43.43.43h.85c.25 0 .43-.18.43-.43V5l6.18 6.18c.18.18.42.18.6 0l.62-.62c.18-.18.18-.42 0-.6L13.62 2a.41.41 0 00-.61 0L5.06 9.94c-.19.19-.19.43 0 .61z"
                />
              </svg>
            </InvisibleButton>
          </div>
        </div>

        <div css={{ flex: "1 1 50%" }}>
          <ValidatedForm
            onSubmit={({ fields }) =>
              submitToNetlify(
                "contact-form",
                ["name", "email", "message"].reduce((data, fieldName) => {
                  data[fieldName] = fields[fieldName].value
                  return data
                }, {})
              ).then((response) => {
                if (response.ok) {
                  setSubmitted(true)
                }
                return false // don't report submitted to ValidatedForm
              })
            }
          >
            {({ fields }) => (
              <>
                <div
                  css={{
                    display: "flex",
                    marginBottom: 20,
                    [theme.mq.max(750)]: {
                      display: "block",
                    },
                  }}
                >
                  <Input
                    name="name"
                    type="text"
                    placeholder="Your name"
                    disabled={submitted}
                    css={{
                      marginRight: 20,
                      flex: "1 1 50%",
                      [theme.mq.max(750)]: {
                        margin: "0 0 20px 0",
                      },
                    }}
                  />
                  <Input
                    name="email"
                    type="email"
                    placeholder="Email address"
                    disabled={submitted}
                    validator={validateEmail}
                    css={{
                      flex: "1 1 50%",
                    }}
                  />
                </div>

                <Input
                  name="message"
                  type="textarea"
                  placeholder="Write your message here"
                  disabled={submitted}
                  css={{ resize: "none", width: "100%", height: 220 }}
                />

                <div
                  css={{ display: "flex", alignItems: "center", marginTop: 20 }}
                >
                  <Button disabled={submitted}>{buttonText}</Button>

                  <div
                    css={{
                      ...theme.p3,
                      color: theme.colors.softText,
                      marginLeft: 20,
                    }}
                  >
                    {submitted ? (
                      successMessage
                    ) : (
                      <StatusMessage fields={fields} />
                    )}
                  </div>
                </div>
              </>
            )}
          </ValidatedForm>
        </div>
      </div>
    </motion.div>
  )
}

export default ContactPanel

const Input = ({ name, type, validator, disabled, placeholder, ...rest }) => {
  const { value = "", errorMessage, ...inputProps } = useFormField({
    name,
    validator,
    required: true,
  })

  return jsx(type === "textarea" ? "textarea" : "input", {
    name,
    value,
    type: type === "textarea" ? null : type,
    required: true,
    css: {
      ...theme.p3,
      appearance: "none",
      display: "block",
      width: "100%",
      border: 0,
      borderRadius: 4,
      boxShadow: theme.shadows.inset,
      background: theme.colors.insetBackground,
      padding: 12,
      paddingBottom: errorMessage && 8,
      borderBottom: errorMessage && `4px solid ${theme.colors.extended.coral}`,
      opacity: disabled && 0.4,
      userSelect: disabled && "none",
      transition: "background-color 5000s ease-in-out 0s",
      ":-webkit-autofill": {
        WebkitTextFillColor: theme.colors.primaryText,
      },
    },
    disabled,
    placeholder: useTranslate(placeholder),
    ...inputProps,
    ...rest,
  })
}

const StatusMessage = ({ fields }) => {
  const [name, field] =
    Object.entries(fields).find(([, { error }]) => error) || []

  const message = field
    ? field.error === "Required"
      ? `${upperFirst(name)} is required`
      : field.error
    : null

  return useTranslate(message)
}

const contactFormQuery = graphql`
  {
    data: sanityContactFormSettings {
      title
      copy: _rawCopy
      successMessage
    }
  }
`
